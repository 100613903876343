'use strict';

// import sliderImage from './modules/sliderImage';
// import langMenu from './modules/langMenu';
import sendForm from './modules/sendForm';
// import menu from './modules/menu';
// import up from './modules/up';
// import inputMask from './modules/inputMask';
// import startLang from './modules/startLang';

window.addEventListener('DOMContentLoaded', () => {
    
    sendForm('.info__form', '.info__submit', 'info__loader', 'info__loader_active', '.modal__close', '.overlay', '.modal__info', 'overlay_show');    
});